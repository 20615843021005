var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    attrs: {
      "disabled": _vm.disabled
    }
  }, [_c('v-card', {
    staticClass: "mb-4",
    attrs: {
      "outlined": ""
    }
  }, [_vm._t("default"), _c('v-card-actions', [_c('vx-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = true;
      }
    }
  }, [_vm._v("Add content here ")])], 1), _c('v-card-text', [_c('v-expansion-panels', [_c('v-draggable', {
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.breakpoint.xs,
      "list": _vm.contents,
      "handle": ".handle"
    },
    on: {
      "change": function change($event) {
        return _vm.onChangeOrder();
      }
    }
  }, _vm._l(_vm.contents, function (content, index) {
    var _content$meta;

    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', [!_vm.breakpoint.xs ? _c('vx-icon', {
      staticClass: "flex-none handle mr-2"
    }, [_vm._v("mdi-drag")]) : _vm._e(), _vm._v(" " + _vm._s((_content$meta = content.meta) === null || _content$meta === void 0 ? void 0 : _content$meta.title) + " "), _c('v-spacer'), _c('div', {
      staticClass: "flex-none"
    }, [_c('vx-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.onMoveUp(index);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-arrow-up")])], 1), _c('vx-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.onMoveDown(index);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-arrow-down")])], 1), _c('vx-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.onRemove(content);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1), _c('v-expansion-panel-content', [_c('ContentInfo', {
      attrs: {
        "options": _vm.options.contentOptions,
        "bookings": _vm.bookings,
        "loading": _vm.loading,
        "content": content
      },
      on: {
        "click": function click($event) {
          _vm.lightbox = index;
        },
        "upload": function upload($event) {
          return _vm.openModal(content);
        },
        "updateContent": _vm.updateContent
      }
    })], 1)], 1);
  }), 1)], 1)], 1)], 2), _c('vx-dialog', {
    attrs: {
      "title": "Add content",
      "scrollable": "",
      "actions": _vm.actions,
      "width": "900"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('div', [_c('v-form', {
    ref: "form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.$validate(_vm.getIframe);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('vx-input', {
    staticClass: "mr-2 w-100",
    attrs: {
      "type": "url",
      "placeholder": "Please enter url",
      "name": "URL",
      "unique": {
        items: _vm.contents,
        key: 'url',
        index: 0
      },
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.$validate(_vm.getIframe);
      }
    },
    model: {
      value: _vm.url,
      callback: function callback($$v) {
        _vm.url = $$v;
      },
      expression: "url"
    }
  }), _c('vx-btn', {
    attrs: {
      "outlined": "",
      "loading": _vm.loading.getIframe,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.getIframe);
      }
    }
  }, [_vm._v(" Retrieve ")])], 1), _vm.hasError ? _c('div', {
    staticClass: "error--text"
  }, [_vm._v(" We are not able to retrieve that URL. Are you sure it's a public link? "), _c('br'), _vm._v(" If you're having issues let us know at "), _c('vx-link', {
    attrs: {
      "support": ""
    }
  })], 1) : _vm._e(), _vm.loading.stillLoading ? _c('div', {
    staticClass: "info--text"
  }, [_vm._v(" We're still working to get your content. Give us just a bit longer. We'll let you know if there's an issue ")]) : _vm._e()]), _vm.content.url ? _c('ContentInfo', {
    attrs: {
      "bookings": _vm.bookings,
      "content": _vm.content,
      "loading": _vm.loading,
      "options": _vm.options.contentOptions
    },
    on: {
      "upload": function upload($event) {
        return _vm.openModal(_vm.content);
      }
    }
  }) : _vm._e()], 1)]), _c('FileUploader', {
    ref: "uploader",
    attrs: {
      "options": _vm.fileUploaderOptions
    },
    on: {
      "resolve": _vm.onResolvFileUploader
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-form :disabled="disabled">
    <v-card outlined class="mb-4">
      <slot />

      <v-card-actions>
        <vx-btn color="primary" @click="dialog = true">Add content here </vx-btn>
      </v-card-actions>

      <v-card-text>
        <v-expansion-panels>
          <v-draggable
            class="w-100"
            :disabled="breakpoint.xs"
            :list="contents"
            handle=".handle"
            @change="onChangeOrder()"
          >
            <v-expansion-panel v-for="(content, index) in contents" :key="index">
              <v-expansion-panel-header>
                <vx-icon v-if="!breakpoint.xs" class="flex-none handle mr-2">mdi-drag</vx-icon>

                {{ content.meta?.title }}
                <v-spacer />

                <div class="flex-none">
                  <vx-btn icon @click.stop="onMoveUp(index)">
                    <vx-icon>mdi-arrow-up</vx-icon>
                  </vx-btn>

                  <vx-btn icon @click.stop="onMoveDown(index)">
                    <vx-icon>mdi-arrow-down</vx-icon>
                  </vx-btn>

                  <vx-btn icon @click.stop="onRemove(content)">
                    <vx-icon>mdi-delete</vx-icon>
                  </vx-btn>
                </div>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <ContentInfo
                  :options="options.contentOptions"
                  :bookings="bookings"
                  :loading="loading"
                  :content="content"
                  @click="lightbox = index"
                  @upload="openModal(content)"
                  @updateContent="updateContent"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-draggable>
        </v-expansion-panels>
      </v-card-text>
    </v-card>

    <vx-dialog v-model="dialog" title="Add content" scrollable :actions="actions" width="900">
      <div>
        <v-form ref="form" @submit.prevent="$validate(getIframe)">
          <div class="d-flex">
            <vx-input
              v-model="url"
              class="mr-2 w-100"
              type="url"
              placeholder="Please enter url"
              name="URL"
              :unique="{
                items: contents,
                key: 'url',
                index: 0,
              }"
              required
              @change="$validate(getIframe)"
            />

            <vx-btn outlined :loading="loading.getIframe" color="primary" @click="$validate(getIframe)">
              Retrieve
            </vx-btn>
          </div>

          <div v-if="hasError" class="error--text">
            We are not able to retrieve that URL. Are you sure it's a public link? <br />
            If you're having issues let us know at <vx-link support />
          </div>

          <div v-if="loading.stillLoading" class="info--text">
            We're still working to get your content. Give us just a bit longer. We'll let you know if there's an issue
          </div>
        </v-form>

        <ContentInfo
          v-if="content.url"
          :bookings="bookings"
          :content="content"
          :loading="loading"
          :options="options.contentOptions"
          @upload="openModal(content)"
        />
      </div>
    </vx-dialog>

    <FileUploader ref="uploader" :options="fileUploaderOptions" @resolve="onResolvFileUploader" />
  </v-form>
</template>

<script>
import _ from 'lodash';
import { Content } from '@tutti/models';
import { BookingService, ContentService, IframelyService } from '@tutti/services';
export default {
  name: 'ContentList',

  components: {
    vDraggable: () => import('vuedraggable'),
    ContentInfo: () => import('./ContentInfo.vue'),
    FileUploader: () => import('@tutti/components/FileUploader.vue'),
  },

  props: {
    options: { type: Object, required: true },
  },

  data() {
    return {
      loading: {
        updateContent: false,
        getIframe: false,
        stillLoading: false,
      },
      disabled: false,
      dialog: false,
      content: new Content(),
      contents: [],
      bookings: [],
      url: '',
      hasError: false,
      actions: {
        noBlock: true,
        loading: false,
        resolve: this.saveContent,
      },
      fileUploaderOptions: {
        maxNumberOfFiles: 1,
        params: {
          from: 'content',
        },
        headers: { authorization: this.$cookies.get(process.env.VUE_APP_AUTH_TOKEN_KEY), partners: false, admin: true },
      },
      lightbox: null,
    };
  },

  created() {
    this.getContents();
    if (this.options.contentOptions.showBookings) {
      this.getBookings();
    }
  },

  methods: {
    openModal(content) {
      this.selectedContent = content;
      this.$refs?.uploader?.openModal();
    },

    onResolvFileUploader(files) {
      if (files && files[0] && this.selectedContent) {
        this.selectedContent.thumbnail = files[0].url;
        this.updateContent(this.selectedContent);
      }
    },

    onMoveUp(index) {
      this.$moveUp(this.contents, index);
      this.onChangeOrder();
    },

    onMoveDown(index) {
      this.$moveDown(this.contents, index);
      this.onChangeOrder();
    },

    async onChangeOrder() {
      const items = this.contents.map((content, index) => {
        return {
          _id: content._id,
          body: {
            sort: index,
          },
        };
      });

      const response = await ContentService.action({ items }, { hideMessage: true });
      if (response) {
        //
      }
    },

    async onRemove(content) {
      const response = await this.$confirm('REMOVE_CONTENT');
      if (response) {
        const response = await ContentService.delete(content._id);

        if (response) {
          this.getContents();
        }
      }
    },

    async getIframe() {
      this.loading.getIframe = true;
      this.hasError = false;
      this.content = new Content();

      const timeout = setTimeout(() => {
        this.loading.stillLoading = true;
      }, 5000);

      const response = await IframelyService.get({ url: this.url });
      if (response) {
        this.content.set(response.data);
      } else {
        this.hasError = true;
      }

      clearTimeout(timeout);
      this.loading.stillLoading = false;
      this.loading.getIframe = false;
    },

    async getBookings() {
      const response = await BookingService.getBookings({
        select: 'hostAccount',
        populate: {
          spaceId: 'name',
          locationId: 'name',
        },
        filter: this.options.filter,
      });

      if (response) {
        this.bookings = _.uniqBy(response.data.data, booking => {
          return booking.locationId._id;
        });
      }
    },

    async getContents() {
      const response = await ContentService.get({ filter: this.options.filter });
      if (response) {
        this.contents = response.data.data;
      }
    },

    setContent(content = {}) {
      if (content.bookingId) {
        const booking = this.bookings.find(booking => booking._id === content.bookingId);
        if (booking) {
          content.spaceId = booking.spaceId?._id;
          content.locationId = booking.locationId?._id;
          content.hostAccount = booking.hostAccount;
        }
        if (content.locationId?._id) {
          content.locationId = content.locationId?._id;
        }
      }

      content.isVerified = this.options.isVerified;

      if (this.options.filter.locationId) {
        content.locationId = this.options.filter.locationId;
        content.hostAccount = this.options.filter.hostAccount;
      } else if (this.options.filter.spaceId) {
        content.spaceId = this.options.filter.spaceId;
        content.hostAccount = this.options.filter.hostAccount;
      } else if (this.options.filter.artistAccount) {
        content.artistAccount = this.options.filter.artistAccount;
      }
    },

    async updateContent(content = {}) {
      this.loading.updateContent = true;

      if (content._id) {
        this.setContent(content);
        await ContentService.save(content);
      }

      this.loading.updateContent = false;
    },

    async saveContent() {
      this.actions.loading = true;

      if (this.content.url) {
        this.content.sort = this.contents.length;
        this.setContent(this.content);

        const response = await ContentService.save(this.content.get());
        if (response) {
          this.content = new Content();
          this.$reset();
          this.getContents();
          this.dialog = false;
        }
      }

      this.actions.loading = false;
    },
  },
};
</script>
